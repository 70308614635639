export function createSVGs(options, waveColor) {
  function setSvgItem(num) {
    return Math.floor(options.sharpness.value * 0.01 * num);
  }

  let waveColorSvg;
  if (waveColor) {
    waveColorSvg = waveColor.replace(/#/g, '%23');
  }

  // prettier-ignore
  return [
    `data:image/svg+xml;utf8,<svg viewBox='0 0 1200  ${setSvgItem(134)}' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 ${setSvgItem(98.3836)}L50 ${setSvgItem(92.3836)}C100 ${setSvgItem(86.3836)} 200 ${setSvgItem(74.3836)} 300 ${setSvgItem(50.3836)}C400 ${setSvgItem(26.3836)} 500 ${setSvgItem(-9.61644)} 600 ${setSvgItem(2.38356)}C700 ${setSvgItem(14.3836)} 800 ${setSvgItem(74.3836)} 900 ${setSvgItem(98.3836)}C1000 ${setSvgItem(122.384)} 1100 ${setSvgItem(110.384)} 1150 ${setSvgItem(104.384)}L1200 ${setSvgItem(98.3836)}V${setSvgItem(134.384)}H1150C1100 ${setSvgItem(134.384)} 1000 ${setSvgItem(134.384)} 900 ${setSvgItem(134.384)}C800 ${setSvgItem(134.384)} 700 ${setSvgItem(134.384)} 600 ${setSvgItem(134.384)}C500 ${setSvgItem(134.384)} 400 ${setSvgItem(134.384)} 300 ${setSvgItem(134.384)}C200 ${setSvgItem(134.384)} 100 ${setSvgItem(134.384)} 50 ${setSvgItem(134.384)}H0V${setSvgItem(98.3836)}Z' fill='${waveColorSvg}'/></svg>`,

    `data:image/svg+xml;utf8,<svg viewBox='0 0 1200 ${setSvgItem(134)}' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 ${setSvgItem(1.98128)}C19.5523 ${setSvgItem(3.77347)} 32.2291 ${setSvgItem(5.66645)} 51.5 ${setSvgItem(9.31693)}C104.5 ${setSvgItem(19.3567)} 200 ${setSvgItem(43.2681)} 300 ${setSvgItem(65.9511)}C400 ${setSvgItem(88.634)} 500 ${setSvgItem(111.317)} 600 ${setSvgItem(102.244)}C700 ${setSvgItem(93.1706)} 800 ${setSvgItem(53.1829)} 900 ${setSvgItem(30.5)}C987.526 ${setSvgItem(5.50399)} 1085.36 ${setSvgItem(-0.278046)} 1150 ${setSvgItem(0.170556)}C1169.54 ${setSvgItem(-0.433546)} 1180.49 ${setSvgItem(0.660948)} 1200 ${setSvgItem(1.98124)}V${setSvgItem(134)}H1150C1100 ${setSvgItem(134)} 1000 ${setSvgItem(134)} 900 ${setSvgItem(134)}C800 ${setSvgItem(134)} 700 ${setSvgItem(134)} 600 ${setSvgItem(134)}C500 ${setSvgItem(134)} 400 ${setSvgItem(134)} 300 ${setSvgItem(134)}C200 ${setSvgItem(134)} 100 ${setSvgItem(134)} 50 ${setSvgItem(134)}H0V1.98128Z' fill='${waveColorSvg}'/></svg>`,

    `data:image/svg+xml;utf8,<svg viewBox='0 0 1200 ${setSvgItem(134)}' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 ${setSvgItem(22.332)}L33 ${setSvgItem(19)}C82 ${setSvgItem(13.5)} 133 ${setSvgItem(14.8889)} 200 ${setSvgItem(29.7778)}C267 ${setSvgItem(44.6667)} 333 ${setSvgItem(67)} 400 ${setSvgItem(70.7222)}C467 ${setSvgItem(74.4444)} 533 ${setSvgItem(59.5556)} 600 ${setSvgItem(40.9444)}C667 ${setSvgItem(22.3333)} 733 0 800 0C867 0 933 ${setSvgItem(22.3333)} 1000 ${setSvgItem(29.7778)}C1067 ${setSvgItem(37.2222)} 1133 ${setSvgItem(29.7778)} 1167 ${setSvgItem(26.0556)}L1200 ${setSvgItem(22.3333)}V${setSvgItem(134)}H1167C1133 ${setSvgItem(134)} 1067 ${setSvgItem(134)} 1000 ${setSvgItem(134)}C933 ${setSvgItem(134)} 867 ${setSvgItem(134)} 800 ${setSvgItem(134)}C733 ${setSvgItem(134)} 667 ${setSvgItem(134)} 600 ${setSvgItem(134)}C533 ${setSvgItem(134)} 467 ${setSvgItem(134)} 400 ${setSvgItem(134)}C333 ${setSvgItem(134)} 267 ${setSvgItem(134)} 200 ${setSvgItem(134)}C133 ${setSvgItem(134)} 67 ${setSvgItem(134)} 33 ${setSvgItem(134)}H0V22.332Z' fill='${waveColorSvg}'/></svg>`,

    `data:image/svg+xml;utf8,<svg viewBox='0 0 1200 ${setSvgItem(134)}' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 ${setSvgItem(0.0103865)}C22.4434 ${setSvgItem(-0.200072)} 28.9842 ${setSvgItem(2.61955)} 55 ${setSvgItem(24.0085)}C94.1849 ${setSvgItem(54.7321)} 121.997 ${setSvgItem(57.4523)} 158.5 ${setSvgItem(58.0062)}C198.5 ${setSvgItem(60.4875)} 200 ${setSvgItem(44.6736)} 240 ${setSvgItem(52.1175)}C280 ${setSvgItem(59.5613)} 320 ${setSvgItem(89.3368)} 360 ${setSvgItem(89.3368)}C400 ${setSvgItem(89.3368)} 440 ${setSvgItem(59.5613)} 480 ${setSvgItem(54.5988)}C520 ${setSvgItem(49.6362)} 560 ${setSvgItem(69.4865)} 600 ${setSvgItem(84.3742)}C640 ${setSvgItem(99.2619)} 680 ${setSvgItem(109.187)} 720 ${setSvgItem(114.15)}C760 ${setSvgItem(119.112)} 800 ${setSvgItem(119.112)} 840 ${setSvgItem(104.225)}C880 ${setSvgItem(89.3368)} 920 ${setSvgItem(59.5613)} 960 ${setSvgItem(62.0426)}C1000 ${setSvgItem(64.5239)} 1040 ${setSvgItem(99.2619)} 1080 ${setSvgItem(94.2994)}C1120 ${setSvgItem(89.3368)} 1127.5 ${setSvgItem(59.8287)} 1147.5 ${setSvgItem(37.4971)}C1171.48 ${setSvgItem(11.8291)} 1185.65 ${setSvgItem(0.127628)} 1200 ${setSvgItem(0.0103865)}V${setSvgItem(134)}H1180C1160 ${setSvgItem(134)} 1120 ${setSvgItem(134)} 1080 ${setSvgItem(134)}C1040 ${setSvgItem(134)} 1000 ${setSvgItem(134)} 960 ${setSvgItem(134)}C920 ${setSvgItem(134)} 880 ${setSvgItem(134)} 840 ${setSvgItem(134)}C800 ${setSvgItem(134)} 760 ${setSvgItem(134)} 720 ${setSvgItem(134)}C680 ${setSvgItem(134)} 640 ${setSvgItem(134)} 600 ${setSvgItem(134)}C560 ${setSvgItem(134)} 520 ${setSvgItem(134)} 480 ${setSvgItem(134)}C440 ${setSvgItem(134)} 400 ${setSvgItem(134)} 360 ${setSvgItem(134)}C320 ${setSvgItem(134)} 280 ${setSvgItem(134)} 240 ${setSvgItem(134)}C200 ${setSvgItem(134)} 160 ${setSvgItem(134)} 120 ${setSvgItem(134)}C80 ${setSvgItem(134)} 40 ${setSvgItem(134)} 20 ${setSvgItem(134)}H0V0.0103865Z' fill='${waveColorSvg}'/></svg>`
  ]
}
